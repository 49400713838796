import {  useState } from 'react'; 
import { useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthPostData } from '../hook';
import contries from '../data/countries.json'
const AddVendor = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [error, setError] = useState('') ;
  const  onSubmit = async (FormData: any) => {
    setLoading(true);
    setError('');
    const response = await AuthPostData('/add-vendor', FormData) ; 
    if(response.success ){ 
      navigate("/vendors"); 
    }
    else{
      setLoading(false);
      setError(response.message);  
    }
     
  };
  return ( 
     <div>
      <PageTitle title="Add Vendor" />  
      
      <div className="add-contact box">
      <h3 className="section-heading">Vendor info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
            <label >Vendor Name</label>
            <input  className="form-control"   {...register("vendor_name", { required: "Vendor Name is required" })} type="text" />
            {errors.vendor_name && <div className='w-100'><span className="error text-red">{errors.vendor_name?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label >Contact No.</label>
            <input  className="form-control"   {...register("contact_no", { required: "Contact No. is required" })} type="text" />
            {errors.contact_no && <div className='w-100'><span className="error text-red">{errors.contact_no?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label >Account No.</label>
            <input  className="form-control"   {...register("account_no", { required: "Account No. is required" })} type="text" />
            {errors.account_no && <div className='w-100'><span className="error text-red">{errors.account_no?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Street</label>
            <input  className="form-control"   {...register("street", { required: "Street is required" })} type="text" />
            {errors.street && <div className='w-100'><span className="error text-red">{errors.street?.message?.toString()}</span></div>} 
          </div>
          <div className="row">
            <div className="col-6 col-sm-3">
              <div className="form-group">
                <label>City</label>
                <input  className="form-control"   {...register("city", { required: "City is required" })} type="text" />
                {errors.city && <div className='w-100'><span className="error text-red">{errors.city?.message?.toString()}</span></div>} 
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <div className="form-group">
                <label>District</label>
                <input  className="form-control"   {...register("district", { required: "District is required" })} type="text" />
                {errors.district && <div className='w-100'><span className="error text-red">{errors.district?.message?.toString()}</span></div>} 
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <div className="form-group">
                <label>Zip</label>
                <input  className="form-control"   {...register("zip", { required: "Zip is required", minLength: {value:5, message: 'Zip incorrect format' }, pattern: {value: /^\d+$/, message: 'Zip incorrect format' } })} type="text"  />
                {errors.zip && <div className='w-100'><span className="error text-red">{errors.zip?.message?.toString()}</span></div>} 
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <div className="form-group">
                <label>Country</label> 
                <select className="form-control select2" {...register("country", { required: "country is required" })}>
                  {contries.map((country, index) => (
                    <option value={country.name}>{country.name}</option>
                  ))
                  }
                </select>
                {errors.country && <div className='w-100'><span className="error text-red">{errors.country?.message?.toString()}</span></div>} 
              </div>
            </div>
          </div>
          
          
          
          
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Telephone</label>
                <input  className="form-control"   {...register("telephone", { required: "telephone is required", pattern:{value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message: 'Invalid phone number'} })} type="text" />
                {errors.telephone && <div className='w-100'><span className="error text-red">{errors.telephone?.message?.toString()}</span></div>} 
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Fax</label>
                <input  className="form-control"   {...register("fax", { required: false })} type="text" />
                {errors.fax && <div className='w-100'><span className="error text-red">{errors.fax?.message?.toString()}</span></div>} 
              </div>
            </div>
          </div>
          
          
          <div className="form-group">
            <label>Email</label>
            <input  className="form-control"   {...register("email", { required: "email is required", pattern: { value: /\S+@\S+\.\S+/, message: "Entered value does not match email format" } })} type="text" />
            {errors.email && <div className='w-100'><span className="error text-red">{errors.email?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Website</label>
            <input  className="form-control"   {...register("website", { required: false, pattern: { value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig, message: "Invaid URL" } })} type="text" />
            {errors.website && <div className='w-100'><span className="error text-red">{errors.website?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Remarks</label>
            <textarea  className="form-control"   {...register("remarks", { required: false })}></textarea>
            {errors.remarks && <div className='w-100'><span className="error text-red">{errors.remarks?.message?.toString()}</span></div>} 
          </div>
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={loading ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Add{loading ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/vendors'}>Cancel</Link>
          
        </form>
       
      </div>    
       
        
    </div>

 
  );
}

export default AddVendor;
