import React, { useEffect, useState } from 'react'; 
import { AuthGetData } from '../hook';
import { Link } from 'react-router-dom';

function Header() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [willClickResult, setWillClickResult] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if(searchKeyword.trim() != ''){
        setSearching(true);
        AuthGetData('/project-search', {keyword: searchKeyword}).then((response) => {
          if(response.success ){ 
            setSearchResults(response.data); 
          }  
          setSearching(false);
        });  
      }
      else{
        setSearchResults([]); 
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [searchKeyword]);
  
  const closeSearchResults = () => {
    if(!willClickResult){
      setOpenSearch(false);
    }  
  }
 
  
  return (
      
<nav className="main-header navbar navbar-expand navbar-white navbar-light">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
    </li>
    <li className="nav-item"> 
        <form className="header-search-form" onBlur={closeSearchResults}>
          <div className="input-group input-group-sm">
            
            <div className="input-group-append">
              <button className="btn btn-navbar header-search-btn" type="submit"> 
                <i className="fas fa-search" />
              </button>
               
            </div>
            <input className="form-control form-control-navbar search-keyword" type="search" placeholder="Search Project" onChange={e => setSearchKeyword(e.target.value)}  onFocus={()=>setOpenSearch(true)}  />
            <div className={"search-results "+ ( openSearch ? "d-block" : "d-none" )} onMouseOver={() => setWillClickResult(true)} onMouseOut={() => setWillClickResult(false)}>
              <ul className="list-group">
              {searching ?  <li className="list-group-item">Searching...</li> : ( 
              searchResults.map((project: any, index) => (
              <>
                <li className="list-group-item p-0"><Link to={"/project?id="+project.id} className="d-block p-2">{project.project_name}</Link></li> 
                
              </>
              )))}
              </ul>
            </div>
          </div>
        </form>
      
    </li>
     
  </ul>
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto d-none">
     
      
    {/* Notifications Dropdown Menu */}
    <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
        <i className="far fa-bell" />
        <span className="badge badge-warning navbar-badge">15</span>
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span className="dropdown-item dropdown-header">15 Notifications</span>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-envelope mr-2" /> 4 new messages
          <span className="float-right text-muted text-sm">3 mins</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-users mr-2" /> 8 friend requests
          <span className="float-right text-muted text-sm">12 hours</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-file mr-2" /> 3 new reports
          <span className="float-right text-muted text-sm">2 days</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>
     
  </ul>
</nav> 

 
  );
}

export default Header;
