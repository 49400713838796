import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { pageTitleState } from '../state';
const Layout = () => {
    const location = useLocation();
    //const pageTitle = useRecoilValue(pageTitleState); 
    const blank_page_paths = ['/login', '/register'];
    if(blank_page_paths.includes(location.pathname)){
        return (
            <Outlet />
        );
    }
    
    return (
    <div className="wrapper">
        <Header />
        <Sidebar /> 
        <div className="content-wrapper"> 
             
            <section className="content">
                <div className="container-fluid">
                <Outlet />
                </div> 
            </section>
        
        </div>
        

    </div> 
    
    );
}

export default Layout;

