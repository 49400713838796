import React, { useEffect, useState } from 'react'; 
import { Controller, useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthGetData, AuthPostData, PostData, UrlParams } from '../hook'; 
import { NumericFormat } from 'react-number-format';
import { Account } from '../types';
import { Bounce, ToastContainer, toast } from 'react-toastify';
const EditAccount = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue , formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [error, setError] = useState('') ; 
  const [accountNotfound, setAccountNotfound] = useState(false) ;
  const [account, setAccount] = useState<Account>({
    id: 0,
    account_number: '',
    account_description: '',  
  }) ;  
  const account_id : any = UrlParams('id') ? UrlParams('id')  : 0;
  useEffect( () => { 
    AuthGetData('/account', {id: account_id}).then((response) => {
      if(response.success ){ 
        if(response.data){
          setAccount(response.data); 
          setValue("account_number",  response.data.account_number );
          setValue("account_description",  response.data.account_description );
          setValue("id",  response.data.id );
        }else{
          setAccountNotfound(true);
          setError('Account not found!');
        }
      } 
      
    })
   
  },[]);
  
  const  onSubmit = async (FormData: any) => {
    setLoading(true);
    setError('');
    
    const response = await AuthPostData('/update-account', FormData) ; 
    if(response.success ){ 
      toast.success('Updated', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    }
    else{
      setLoading(false);
      setError(response.message);
    }
     
  };
  return ( 
     <div>
      <ToastContainer />
      <PageTitle title="Add Acount" />  
      
      <div className="add-project box">
      <h3 className="section-heading">Account info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
           
          <div className="form-group">
            <label>Account Number </label>
            <Controller
              name="account_number"
              rules={{ required: 'Account Number is required' }}
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <NumericFormat 
                  className="form-control"   
                  getInputRef={ref}
                  {...rest}
                  
                />
              )}
            />  
            {errors.account_number && <div className='w-100'><span className="error text-red">{errors.account_number?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Account Description </label>
            <input  className="form-control"   {...register("account_description", { required: "Account Description is required" })} type="text"  />
            {errors.account_description && <div className='w-100'><span className="error text-red">{errors.account_description?.message?.toString()}</span></div>} 
          </div>
          
            
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={loading ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Update{loading ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/accounts'}>Cancel</Link>
        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default EditAccount;
