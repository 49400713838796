import React, { Component, FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom"; 
import { RecoilRoot } from 'recoil';
import logo from './logo.svg';
import './App.css';
import './components/Header';
import Layout from './components/Layout';
import Home from './pages/Home'; 
import Login from './pages/Login';
import Payments from './pages/Payments';
import Page404 from './pages/Page404';
import Project from './pages/Project';
import Contract from './pages/Contract';
import AddEditProject from './pages/AddEditProject';
import AddEditContract from './pages/AddEditContract';
import Vendors from './pages/Vendors';
import AddVendor from './pages/AddVendor';
import EditVendor from './pages/EditVendor';
import AddAccount from './pages/AddAccount'; 
import EditAccount from './pages/EditAccount';
import Accounts from './pages/Accounts';
import Users from './pages/Users'; 
import Report from './pages/Report'
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';

 

function App() { 
  

  const RequireAuth: FC<{ children: any }> = ({ children }) => {
    let authed = false;
    if (window.sessionStorage.getItem('token')) {
      authed = true;
    }
    const location = useLocation();
  
    return ( authed ? 
      children
      : <Navigate to="/login" replace state={{ path: location.pathname }}  />);
  }
  
  
  return (
    <RecoilRoot>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<RequireAuth><Home /></RequireAuth>}  />
          <Route path="/login" element={<Login />} />  
            
          <Route path="/contract" element={<RequireAuth><Contract /></RequireAuth>} />
          <Route path="/add-contract" element={<RequireAuth><AddEditContract /></RequireAuth>} /> 
          <Route path="/project" element={<RequireAuth><Project /></RequireAuth>} /> 
          <Route path="/add-project" element={<RequireAuth><AddEditProject /></RequireAuth>} /> 

          <Route path="/vendors" element={<RequireAuth><Vendors /></RequireAuth>} />
          <Route path="/add-vendor" element={<RequireAuth><AddVendor /></RequireAuth>} />   
          <Route path="/edit-vendor" element={<RequireAuth><EditVendor /></RequireAuth>} />

          <Route path="/accounts" element={<RequireAuth><Accounts /></RequireAuth>} />
          <Route path="/add-account" element={<RequireAuth><AddAccount /></RequireAuth>} /> 
          <Route path="/edit-account" element={<RequireAuth><EditAccount /></RequireAuth>} />

          <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} />
          <Route path="/add-user" element={<RequireAuth><AddUser /></RequireAuth>} /> 
          <Route path="/edit-user" element={<RequireAuth><EditUser /></RequireAuth>} /> 
          <Route path="/report" element={<RequireAuth><Report /></RequireAuth>} /> 
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
