import React, { useEffect, useState } from 'react';  
import  PageTitle  from '../components/PageTitle';
import { Link } from 'react-router-dom'; 
import { AuthGetData } from '../hook'; 
import Loader from '../components/Loader';
 

const Accounts = () => { 
  const [accounts, setAccounts] = useState([]) ;
  const [loading, setLoading] = useState(false) ;
  useEffect( () => {
    setLoading(true); 
    AuthGetData('/accounts', {}).then((response) => {
    console.log(response);
      if(response.success ){ 
        setAccounts(response.data);
      } 
      else{
         
      }
      setLoading(false);
    });  
     
  },[]);
  return ( 
     <div>
      <PageTitle title="Vendors" />
       

      <div className="py-2 mb-3"><Link to="/add-account" className="btn btn-primary">Add Account</Link> </div>

      <div className="vendors-list list-data"> 
        <div className="table-container">
          <table className ="table table-bordered table-hover border-0 data-tbl">
            <thead>
            <tr>
              <th>ID</th>
              <th>Account Number</th> 
              <th>Account Description</th> 
              <th className="action-col"></th>
                
            </tr>
            </thead>
            <tbody>
              {loading ? <tr><td colSpan={6}><Loader /></td></tr> : ( 
              accounts.map((account: any, index) => (
              <tr key={index}>
                <td>{account.id}</td>
                <td>{account.account_number}</td>
                <td>{account.account_description}</td> 
                
                <td>
                <Link to={"/edit-account?id="+account.id} className="action-btn">Edit</Link>  
                </td>
              </tr> 
              ))
              )
              }
              
            
            </tbody>
            
          </table>
        </div>
        
      </div>


    </div>

 
  );
}

export default Accounts;
