import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react'; 
import $ from 'jquery';   
import  PageTitle  from '../components/PageTitle';
import { Link } from 'react-router-dom'; 
import { AuthGetData, AuthPostData } from '../hook';
import ProjectsList from '../components/ProjectsList'; 



const Home = () => { 
  
  const [projectInfo, setprojectInfo] = useState<any>({}) ;  
  const [contractInfo, setcontractInfo] = useState<any>({}) ;  
  const [paymentInfo, setpaymentInfo] = useState<any>({}) ;  
  useEffect( () => { 
    AuthGetData('/project-count', {}).then((response) => {
      if(response.success ){ 
        setprojectInfo(response.data); 
      }  
    }); 
    AuthGetData('/contract-count', {}).then((response) => {
      if(response.success ){ 
        setcontractInfo(response.data); 
      }  
    }); 
    
    AuthGetData('/payment-count', {}).then((response) => {
      if(response.success ){ 
        setpaymentInfo(response.data); 
      }  
    }); 
  },[]);
  return ( 
     <div>
      <PageTitle title="Dashboard" />
      <div className="row mb-5">
        <div className="col-lg-4 col-6 pb-3">
          <div className="small-box bg-white info-box">
            <div className="inner">
              <p className="heading">Projects</p>
              <h3>{projectInfo.total}</h3>
              <div className="info-details">{projectInfo.completed} Completed<br />{projectInfo.inprogress} In Progress</div> 
              
            </div>
            <div className="icon">
              <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.21" d="M0 30V37C0 49.7025 10.2975 60 23 60H29H35C47.7025 60 58 49.7025 58 37V30V23C58 10.2975 47.7025 0 35 0H29H23C10.2975 0 0 10.2975 0 23V30Z" fill="#8280FF"/>
              <rect opacity="0.01" x="15" y="15" width="29" height="30" fill="#4C48FB"/>
              <path d="M36.75 20V23.75C36.75 25.1307 35.668 26.25 34.3333 26.25H24.6667C23.332 26.25 22.25 25.1307 22.25 23.75V20C20.248 20 18.625 21.6789 18.625 23.75V38.75C18.625 40.8211 20.248 42.5 22.25 42.5H36.75C38.752 42.5 40.375 40.8211 40.375 38.75V23.75C40.375 21.6789 38.752 20 36.75 20Z" fill="#4C48FB"/>
              <rect x="23.4583" y="17.5" width="12.0833" height="7.5" rx="1" fill="#4C48FB"/>
              </svg> 
            </div> 
          </div>
        </div>
        <div className="col-lg-4 col-6 pb-3">
          <div className="small-box bg-white info-box">
            <div className="inner">
              <p className="heading">Contracts</p>
              <h3>{contractInfo.total}</h3>
              <div className="info-details">{contractInfo.fulfilled} Fulfilled<br />{contractInfo.pending} Pending</div> 
              
            </div>
            <div className="icon">
              <svg width="59" height="60" viewBox="0 0 59 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.21" d="M0 30V37C0 49.7025 10.2975 60 23 60H29.3463H35.6926C48.3952 60 58.6926 49.7025 58.6926 37V30V23C58.6926 10.2975 48.3952 0 35.6926 0H29.3463H23C10.2975 0 0 10.2975 0 23V30Z" fill="#FEC53D"/>
              <path  d="M14.6732 24.3165L27.2926 31.7646C27.4284 31.8448 27.5709 31.9027 27.716 31.9395V46.3847L15.5732 39.0385C15.0153 38.701 14.6732 38.0876 14.6732 37.4249V24.3165ZM44.0195 24.1185V37.4249C44.0195 38.0876 43.6773 38.701 43.1195 39.0385L30.9767 46.3847V31.8129C31.0063 31.7978 31.0356 31.7817 31.0646 31.7646L44.0195 24.1185Z" fill="#FEC53D"/>
              <path opacity="0.499209" d="M15.0696 20.7014C15.2237 20.5024 15.4183 20.3343 15.6451 20.2108L28.4841 13.2201C29.0231 12.9266 29.6696 12.9266 30.2086 13.2201L43.0476 20.2108C43.2224 20.306 43.3781 20.4277 43.5109 20.5697L29.4343 28.8778C29.3417 28.9325 29.2564 28.995 29.1786 29.064C29.1008 28.995 29.0155 28.9325 28.9229 28.8778L15.0696 20.7014Z" fill="#FEC53D"/>
              </svg>

            </div> 
          </div>
        </div>
        <div className="col-lg-4 col-6 pb-3">
          <div className="small-box bg-white info-box">
            <div className="inner">
              <p className="heading">Payments</p>
              <h3>{paymentInfo.total}</h3>
              <div className="info-details">{paymentInfo.pending} Pending<br />
              {paymentInfo.settled} Paid in Ful</div> 
              
            </div>
            <div className="icon">
              <svg width="57" height="59" viewBox="0 0 57 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.21" d="M0 29.8168V36C0 48.7025 10.2975 59 23 59H28.2387H33.4775C46.18 59 56.4775 48.7025 56.4775 36V29.8168V23.6335C56.4775 10.931 46.18 0.633545 33.4774 0.633545H28.2387H23C10.2974 0.633545 0 10.931 0 23.6335V29.8168Z" fill="#4AD991"/>
              <path d="M17.9891 40.4092H39.9526C40.7613 40.4092 41.4168 41.0867 41.4168 41.9224C41.4168 42.7581 40.7613 43.4356 39.9526 43.4356H16.5249C15.7162 43.4356 15.0607 42.7581 15.0607 41.9224V17.7111C15.0607 16.8754 15.7162 16.1979 16.5249 16.1979C17.3336 16.1979 17.9891 16.8754 17.9891 17.7111V40.4092Z" fill="#4AD991"/>
              <path opacity="0.5" d="M23.45 33.8781C22.897 34.4878 21.9703 34.5187 21.3804 33.9471C20.7904 33.3755 20.7605 32.4179 21.3136 31.8082L26.8045 25.7554C27.3394 25.1657 28.2282 25.1146 28.8232 25.6393L33.1569 29.4611L38.8034 22.0697C39.3044 21.4137 40.2252 21.3018 40.8599 21.8196C41.4946 22.3375 41.6029 23.289 41.1019 23.945L34.5128 32.5702C33.9982 33.2439 33.0449 33.3408 32.4131 32.7837L27.9852 28.8788L23.45 33.8781Z" fill="#4AD991"/>
              </svg>

            </div> 
          </div>
        </div>
         
      </div>

      <div className="py-2 mb-3"><Link to="/add-project" className="btn btn-primary">Add Project</Link> </div>

      <div className="projects-list list-data">
        <h3 className="section-heading">Projects</h3>
        
         
          <ProjectsList /> 
        
      </div>


    </div>

 
  );
}

export default Home;
