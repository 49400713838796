 import {  AuthGetData, AuthPostData, UrlParams} from '../hook'; 
import PageTitle from '../components/PageTitle';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import Loader from '../components/Loader';
import dateFormat from 'dateformat';
import { Controller, useForm } from 'react-hook-form';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  

const Contract = () => {
  
  const contract_id : any = UrlParams('id') ? UrlParams('id')  : 0;;
  const [payments, setPayments] = useState<any>([]);
  const [selectedPayments, setSelectedPayments] = useState<any>([]);
  const [selectedPaymentIDs, setSelectedPaymentIDs] = useState(Array);
  const [paidIDs, setPaidIDs] = useState(Array); 
  const [items, setItems] = useState([]) ;
  const [trades, setTrades] = useState([]) ;
  const [accountOptions, setAccountOptions] = useState([]) ;
  const [responsibles, setResponsibles] = useState([]) ; 
  const [loading, setLoading] = useState(true) ; 
  const [downloading, setDownloading] = useState(false) ;
  const [contractNotfound, setContractNotfound] = useState(false) ; 
  const [contractInfo, setContractInfo] = useState<any>([]) ;
  const [paymentInfo, setPaymentInfo] = useState({
    method: 'Cheque', cheque_card_no : '', date: '', account_id: 0, account_name: '', bank_name: ''
  }) ; 
  const { register, handleSubmit, control, setValue , formState: { errors } } = useForm();
  
  useEffect( () => {
    AuthGetData('/contract', {id: contract_id}).then((response) => {
      if(response.success ){ 
        setContractInfo(response.data);
      }  
      else{
        setContractNotfound(true);
      }
    })
    AuthGetData('/payments', {contract_id: contract_id}).then((response) => {
      if(response.success ){ 
        setPayments(response.data);
      }  
      setLoading(false);
    });  
    AuthGetData('/items', {}).then((response) => {
      if(response.success ){ 
        setItems(response.data);
      }  
    });  
    AuthGetData('/trades', {}).then((response) => {
      if(response.success ){ 
        setTrades(response.data);
      }  
    });  
    AuthGetData('/user-options', {}).then((response) => {
      if(response.success ){ 
        setResponsibles(response.data);
      }  
    });  
    AuthGetData('/account-options', {}).then((response) => {
      if(response.success ){ 
        setAccountOptions(response.data);
      }  
    }); 
     
  },[]);
  const addPayment = () => {
    setLoading(true);
    AuthPostData('/add-payment', {contract_id: contract_id, uid: window.sessionStorage.getItem("uid")}).then((response) => {
      if(response.success ){   
        setPayments( (currPayments:any) => [ response.data, ...currPayments]);
      }  
      setLoading(false);
    });   
  };
  const deleteInvoice = (invoice_id : number) => {
    let res = [...payments]; 
    
    setLoading(true);
    AuthPostData('/delete-payment', {uid: window.sessionStorage.getItem("uid"), payment_id: invoice_id}).then((response) => {
      if(response.success ){   
        const index = res.findIndex( (payment: any) => payment.id === invoice_id );  
        res.splice(index, 1);
        setPayments(res);
      }  
      setLoading(false);
    });   

    
  }

  const updatePaymentData = (paymentID:Number, attr:string, value: any) => {
    let res = [...payments];   
    const index = payments.findIndex( (payment:any) => payment.id === paymentID ); 
    res[index][attr] = value;
    setPayments(res);
  }
  const updateSelectedPaymentData = (paymentID:Number, attr:string, value: any) => {
    let res = [...selectedPayments];   
    
    const index = selectedPayments.findIndex( (payment:any) => payment.id === paymentID ); 


    res[index][attr] = value;
    setSelectedPayments(res); 
  }

  const selectPayment = (checked: boolean, value: string) => {  
    if(!checked){
      const p  = selectedPaymentIDs.filter((id) => id !== value); 
      setSelectedPaymentIDs(p);
    }
    else{
      setSelectedPaymentIDs( currSelectedPaymentIDs => [ value, ...currSelectedPaymentIDs]);
    }  
  }

  const addPaymentsToPay = () => {  
    //const paymentsPay = payments.filter((payment:any) => selectedPaymentIDs.includes( payment.id.toString())); 
    let paymentsPay:any = [];
    payments.map( (payment:any) =>  { 
      
      if(selectedPaymentIDs.includes( payment.id.toString()) && payment.status != 'Settled' ){
        paymentsPay.push(payment);
        setValue("responsible"+payment.id, {value: payment.responsible_user_id, label: payment.responsible});
        if(payment.item_id){
          setValue("item"+payment.id, {value: payment.item_id, label: payment.item_name});
        }
        if(payment.trade_id){
          setValue("trade"+payment.id, {value: payment.trade_id, label: payment.trade_name});
        }
        
        setValue("percent"+payment.id,  payment.percent );
        setValue("remarks"+payment.id,  payment.remarks ); 
        setValue("amount"+payment.id,  payment.amount );
        
      }
      
    }) 
    setSelectedPayments(paymentsPay);  
    if(paymentsPay.length > 0){
      $('.showInvoicesModal').trigger('click');
    }
    else{
      
      toast.warn('No invoices selected!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    }
    
  }
  const updatePaymentInfo = (attr: any, value: any) => {
    let res: any = {...paymentInfo};
    res[attr] = value; 
    setPaymentInfo(res);
  }

  const  makePayments = async (FormData: any) => {
  
    let res = [...payments];   
    let paid = [...paidIDs];
    selectedPayments.forEach((payment: any) => {
      
      const index = payments.findIndex( (_payment:any) => _payment.id === payment.id ); 
      paid.push(payment.id);
      setPaidIDs(paid);
      res[index]['status'] = "Settled";
      res[index]['payment_method'] = paymentInfo.method;
      res[index]['cheque_number'] = paymentInfo.cheque_card_no;
      res[index]['account_id'] = paymentInfo.account_id;
      res[index]['bank_name'] = paymentInfo.bank_name;
      setPayments(res);
      $('.closeInvoicesModal').trigger('click');
      toast.success('Payments made', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
       
    });
    setSelectedPaymentIDs([]);
     /*
    setLoading(true);
    AuthPostData('/pay', {payments:selectedPayments}).then((response) => {
      if(response.success ){   
        
      }  
      setLoading(false);
    });
    */
  };
  const saveChanges = () => {
    setLoading(true);
    AuthPostData('/update-payments', {payments:payments, paidIDs: paidIDs}).then((response) => {
      if(response.success ){   
        setPaidIDs([]);
        toast.success('Updated', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }  
      setLoading(false);
    });
  }
  const generateInvoices = () => {
    if(selectedPaymentIDs.length < 1){
      alert('Please select invoice!');
      return;
    }
    
    let invoice_ids:any = [];
    payments.map( (payment:any) =>  {  
      if(selectedPaymentIDs.includes( payment.id.toString()) && payment.status == 'Settled' ){
        invoice_ids.push(payment.id);  
      } 
    }) 
    if(invoice_ids.length < 1){
      alert('You selected Unpaid invoice(s)');
      return;
    }

    setDownloading(true);
    invoice_ids.forEach(function(id:any) {
        
      const url = process.env.REACT_APP_API_ENDPOINT + '/generate-invoice?id='+id;
      //const accessToken = localStorage.getItem('token');
      const accessToken = window.sessionStorage.getItem("token");
      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+accessToken, 
          'Content-Type': 'application/json'
        }, 
      }) 
      .then((response) => response.blob())
      .then((myBlob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(myBlob);
          
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "invoice"+id+".pdf";
        alink.click();
        setDownloading(false);
      });
    });
  }

      

  if(contractNotfound){
    return (
      <div className="pt-3"><div className='alert alert-danger'>Contract Not Found!</div></div>
    );
  }
  return ( 
    <>
     <div>
      <PageTitle title="Contract Details" />    
      <div className="contract-filter">
        <div className="row align-items-center">
          <div className="col-auto">Vendor ID:</div>
          <div className="col-auto "><div className="select-vendor">{contractInfo.vendor_name+ ' (ID:'+ contractInfo.vendor_id+')'} <i className="nav-icon toggle-icon fas fa-angle-down"></i></div> </div>
          <div className="col-sm-6 mt-sm-0 mt-2"><button className="action-btn">Select Vendor From List</button></div>
          
        </div> 
      </div>
      <div className="contract-info my-3 bg-white p-3">
        <div className="row">
          <div className="col-xl-8 col-sm-12">
            <div className="row">
              <div className="col-sm-6"> 
                <div className="row align-items-center mb-3">
                  <div className="col-5">
                    <span>Created On:</span> 
                  </div>
                  <div className="col-7">
                    <input type="text" className="form-control input-text" value={dateFormat(contractInfo.created_at, "dd.mm.yyyy") } disabled />
                  </div>
                </div> 
              </div>
              <div className="col-sm-6"> 
                <div className="row align-items-center mb-3">
                  <div className="col-5 text-sm-right">
                    <span>Created By:</span> 
                  </div>
                  <div className="col-7">
                    <input type="text" className="form-control input-text" value={contractInfo.created_by}  disabled />
                  </div>
                </div> 
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6"> 
                <div className="row align-items-center mb-3">
                  <div className="col-5">
                    <span>Last Modified:</span> 
                  </div>
                  <div className="col-7">
                    <input type="text" className="form-control input-text" value={dateFormat(contractInfo.updated_at, "dd.mm.yyyy") } disabled />
                  </div>
                </div> 
              </div>
              <div className="col-sm-6"> 
                <div className="row align-items-center mb-3">
                  <div className="col-5 text-sm-right">
                    <span>Modified By:</span> 
                  </div>
                  <div className="col-7 ">
                    <input type="text" className="form-control input-text" value={contractInfo.modify_by} disabled />
                  </div>
                </div> 
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-sm-6 pl-xl-5">
            <div className="row align-items-center mb-3">
              <div className="col-5 col-xl-6 text-left">
                <span>Contract Status:</span> 
              </div>
              <div className="col-7 col-xl-6 text-center">
                <span className="status bg-red">Open</span>
              </div>
            </div> 
            <div className="row  align-items-center mb-3">
              <div className="col-5 col-xl-6 text-left">
                <span>Contract ID</span> 
              </div>
              <div className="col-7 col-xl-6 text-center">
                <input type="text" className="form-control input-text" value={contractInfo.id}  disabled />
              </div>
            </div> 
          </div>
        </div>
      </div>

      <div className="py-2 mb-3"><button className="btn btn-primary" onClick={addPayment}>Add Payment/Invoice</button></div>
      
      

      <div className="payments-list list-data">
      <h3 className="section-heading">Invoices / Payments Related to Contract</h3>
        <div className={loading ? 'table-container loading' : 'table-container'}>
          <table className ="table table-bordered table-hover border-0 data-tbl">
            <thead>
              <tr>
                <th></th>
                <th>Invoice No.</th>
                <th>Responsible</th>
                <th>%</th>
                <th>Trade</th>
                <th>Item</th> 
                <th>Description</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            { 
                 
            (payments.length < 1 ) ? 
              <tr><td colSpan={11}>No Invoice!</td></tr> : ( 
              payments.map( (payment:any) =>  (
              <tr key={payment.id}>
                  <td><input type="checkbox" name="cb" value={payment.id} onChange={e => selectPayment( e.currentTarget.checked, e.currentTarget.value )} checked={selectedPaymentIDs.includes(payment.id.toString()) ? true : false}  /></td>
                  <td>#{payment.invoice_no}</td>
                  <td>
                    <Select options={responsibles} value={{value: payment.responsible_user_id, label: payment.responsible? payment.responsible : 'Select responsible' }} onChange={(option, actionMeta) => {
                       updatePaymentData(payment.id,'responsible_user_id', option?.value);
                       updatePaymentData(payment.id,'responsible', option?.label); 
                      }}></Select> 
                    
                  </td>
                  <td><input type="text"  className="form-control input-text percent text-center" value={payment.percent} onChange={(e) => { updatePaymentData(payment.id,'percent', e.target.value); }} /></td>
                  <td>
                    <Select options={trades}   value={{value: payment.trade_id, label: payment.trade_name? payment.trade_name : 'Select Trade'  }} onChange={(option, actionMeta) => {
                       updatePaymentData(payment.id,'trade_id', option?.value);
                       updatePaymentData(payment.id,'trade_name', option?.label); 
                      }} ></Select> 
                  </td>
                  <td>
                    <Select options={items}  value={{value: payment.item_id, label: payment.item_name? payment.item_name : 'Select Item'  }} onChange={(option, actionMeta) => {
                       updatePaymentData(payment.id,'item_id', option?.value);
                       updatePaymentData(payment.id,'item_name', option?.label); 
                      }} ></Select> 
                  </td>
                  <td>
                    <input type="text"  className="form-control input-text" value={payment.remarks} onChange={(e) => { updatePaymentData(payment.id,'remarks', e.target.value);}} />
                  </td>
                  <td><input type="number"  className="form-control input-text" value={payment.amount} onChange={(e) => { 
                    updatePaymentData(payment.id,'amount', e.target.value);
                    //updatePaymentData(payment.id,'percent', payment.amount * 100 /  contractInfo.amount);
                    
                    }}/></td>
                  <td><span className={payment.status == "Unpaid" ? "status bg-red" : "status bg-green"}>{payment.status}</span></td> 
                  <td>12.09.2019</td>
                  <td><button  className="action-btn"  onClick={() => {
                    const confirmBox = window.confirm(
                      "Do you really want to delete this invoice?"
                    )
                    if (confirmBox === true) {
                      deleteInvoice(payment.id)
                    }
                    }}>Delete</button>
                  </td>
                </tr>
              )) )
            }
              
              
            </tbody>
              
          </table>
        </div>    
        <div className="text-right">
          <button className="btn btn-primary mr-2"  onClick={addPaymentsToPay}>Pay Selected</button>
          <button className="d-none showInvoicesModal" data-toggle="modal" data-target="#payInvoicesModal">InvoicesModal</button>
          <button className={"btn btn-primary " + (downloading ? 'loading disabled' : '')} onClick={generateInvoices} >Generate selected invoice</button>
           
        </div>   
      </div>
    </div>
    <div className="pt-2 pb-5 text-right">
      <button className="btn btn-light bg-gray mr-2" onClick={()=>{console.log(selectedPayments);}}>Revert Changes</button>
      <button className="btn btn-primary" onClick={saveChanges}>Save Changes</button>
    </div>
    <ToastContainer />  
    {/* Lightbox */}
    <div className="modal fade" id="payInvoicesModal" aria-labelledby="apayInvoicesModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
      <form onSubmit={handleSubmit(makePayments)}  className={loading ? 'make-payment-form loading' : 'make-payment-form'}>
        <div className="modal-content">
          
          
            <div className="modal-header">
              <h5 className="modal-title" id="apayInvoicesModalLabel">Settle Payment #2818381</h5>
              <div className=''>Belongs to Contrac: CO-19291884 
              </div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
          
              <p>Details</p>
              <div className="row justify-content-between align-items-center mb-3">
                <div className="col-sm-6 col-md-4 col-12">
                  <div className="row align-items-center mb-3 ">
                    <div className="col-sm-5 col-4">Vendor ID:</div>
                    <div className="col-sm-6 col-8">
                      <input type="text" className="form-control input-text" value={contractInfo.vendor_name+ ' (ID:'+ contractInfo.vendor_id+')'} disabled/>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-12">
                  <div className="row align-items-center mb-3">
                    <div className="col-sm-5 col-4">Payment Method:</div> 
                    <div className="col-sm-6 col-8">
                      <select value={paymentInfo.method} {...register("method", { required: false })} onChange={ (e) => {updatePaymentInfo('method', e.target.value)}}>
                        <option>Cheque</option>
                        <option>Debit Card</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-12">
                  <div className="row align-items-center mb-3  ">
                    <div className="col-sm-5 col-4 offset-sm-0 offset-md-1 ">{paymentInfo.method} No:</div>
                    <div className="col-sm-6 col-8">
                      <input type="text" className="form-control input-text" value={paymentInfo.cheque_card_no} {...register("cheque_card_no", { required: "This field is required", pattern: {value: /^\d+$/, message: 'Must be number!' } })}  onChange={ (e) => { updatePaymentInfo('cheque_card_no', e.target.value)} } />
                      {errors.cheque_card_no && <div className='w-100'><span className="error text-red">{errors.cheque_card_no?.message?.toString()}</span></div>} 
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-12">
                  <div className="row align-items-center mb-3 ">
                    <div className="col-sm-5 col-4">Date:</div>
                    <div className="col-sm-6 col-8">
                      <input type="text" className="form-control input-text" value={paymentInfo.date} {...register("payment_date", { required: "Date is required" })} onChange={ (e) => { updatePaymentInfo('date', e.target.value)} }/>
                      {errors.payment_date && <div className='w-100'><span className="error text-red">{errors.payment_date?.message?.toString()}</span></div>}  
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-12">
                  <div className="row align-items-center mb-3">
                    <div className="col-sm-5 col-4">Cash Account:</div> 
                    <div className="col-sm-6 col-8">
                      <Controller
                        name={"account_id"}
                        control={control}
                        rules={{ required: 'Please select account' }} 
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select options={accountOptions}   value={{value: paymentInfo.account_id, label: paymentInfo.account_name? paymentInfo.account_name : 'Select Account'  }} defaultValue={{value: paymentInfo.account_id, label: paymentInfo.account_name? paymentInfo.account_name : 'Select Account'  }} onChange={(option, actionMeta) => {
                          setValue("account_id", option?.value); 
                          let res: any = {...paymentInfo};
                          res.account_id = option?.value;
                          res.account_name = option?.label;
                          setPaymentInfo(res);
                        }} ></Select> 
                        )}
                      />  
                      {errors.account_id && <div className='w-100'><span className="error text-red">{errors.account_id?.message?.toString()}</span></div>}  
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-12">
                  <div className="row align-items-center mb-3  ">
                    <div className="col-sm-5 col-4 offset-sm-0 offset-md-1">Bank name:</div>
                    <div className="col-sm-6 col-8">
                      <input type="text" className="form-control input-text"  value={paymentInfo.bank_name} {...register("bank_name", { required: "Bank name is required" })}  onChange={ (e) => { updatePaymentInfo('bank_name', e.target.value)} }/>
                      {errors.bank_name && <div className='w-100'><span className="error text-red">{errors.bank_name?.message?.toString()}</span></div>}  
                    </div>
                  </div>
                </div>
              </div>
              <table className ="table table-bordered table-hover border-0 data-tbl">
                <thead>
                  <tr>
                    
                    <th>Invoice No.</th>
                    <th>Responsible</th>
                    <th>%</th>
                    <th>Trade</th>
                    <th>Item</th> 
                    <th>Description</th>
                    <th>Due Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    
                      
                  </tr>
                </thead>
                <tbody>
                {selectedPayments.map((payment:any) =>  
                <tr key={'lb'+payment.id}>
                      
                    <td>#{payment.invoice_no}</td>
                    <td className={errors["responsibles"+payment.id] ? 'error' : ''}>
                       
                      <Controller
                        name={"responsible"+payment.id}
                        control={control}
                        rules={{ required: 'Please select a responsible' }}
                        
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Select options={responsibles} onChange={ (e:any) => {setValue("responsible"+payment.id, e?.value); updateSelectedPaymentData(payment.id,'responsible_user_id', e?.value);updateSelectedPaymentData(payment.id,'responsible', e?.label);  } }  onBlur={onBlur} value={{value: payment.responsible_user_id, label: payment.responsible}}  defaultValue={{value: payment.responsible_user_id, label: payment.responsible}} ></Select> 
                        )}
                      />  
                    </td>
                    <td className={errors["percent"+payment.id] ? 'error' : ''}>
                      <input type="text" {...register("percent"+payment.id, { required: "Required", pattern: {value: /^\d+$/, message: 'Must be number!' } })}  className="form-control input-text percent text-center" value={payment.percent} onChange={(e) => { updateSelectedPaymentData(payment.id,'percent', e.target.value); }}  />
                       
                    </td>
                    <td className={errors["trade"+payment.id] ? 'error' : ''}>
                      <Controller
                        name={"trade"+payment.id}
                        control={control}
                        rules={{ required: 'Please select trade' }}
                        
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select options={trades}   value={{value: payment.trade_id, label: payment.trade_name? payment.trade_name : 'Select Trade'  }} defaultValue={{value: payment.trade_id, label: payment.trade_name? payment.trade_name : 'Select Trade'  }} onChange={(option, actionMeta) => {
                          setValue("trade"+payment.id, option?.value);
                          updateSelectedPaymentData(payment.id,'trade_id', option?.value);
                          updateSelectedPaymentData(payment.id,'trade_name', option?.label); 
                          }} ></Select> 
                          )}
                      />  
                    </td>
                    <td className={errors["item"+payment.id] ? 'error' : ''}>
                    <Controller
                        name={"item"+payment.id}
                        control={control}
                        rules={{ required: 'Please select item' }}
                        
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select options={items}  value={{value: payment.item_id, label: payment.item_name? payment.item_name : 'Select Item'  }} onChange={(option, actionMeta) => {
                          setValue("item"+payment.id, option?.value);
                          updateSelectedPaymentData(payment.id,'item_id', option?.value);
                          updateSelectedPaymentData(payment.id,'item_name', option?.label); 
                          }} ></Select> )}
                        />  
                    </td>
                    <td >
                      <input type="text" {...register("remarks"+payment.id, { required: false })}  className="form-control input-text" value={payment.remarks} onChange={(e) => { updateSelectedPaymentData(payment.id,'remarks', e.target.value);}} />
                    </td>
                    <td>12.09.2019</td>
                    <td className={errors["amount"+payment.id] ? 'error' : ''}>
                      <input type="number" {...register("amount"+payment.id, { required: "Required",  pattern: {value: /^\d+$/, message: 'Must be number!' } })} className="form-control input-text" value={payment.amount} onChange={(e) => { 
                      updateSelectedPaymentData(payment.id,'amount', e.target.value);                    
                      }}/></td>
                    <td><span className="status bg-red">Unpaid</span></td>
                    
                    
                  </tr>
                  )}
                  
                  
                </tbody>
                  
              </table>
            
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary closeInvoicesModal" data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary">Make Payment</button>
            </div>
          
        </div>
        </form>
      </div>
    </div>
    {/* Lightbox */}
      

  </>
    

 
  );
}

export default Contract;
