import React, { useEffect, useState } from 'react'; 
import { Controller, useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthGetData, AuthPostData, PostData, UrlParams } from '../hook'; 
import { User } from '../types';
import { Bounce, ToastContainer, toast } from 'react-toastify';
const EditUser = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue ,watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [userNotfound, setUserNotfound] = useState(false) ;
  const [error, setError] = useState('') ;  
  const [user, setUser] = useState<User>({
    id: 0,
    name: '', 
    email: '',
    password: ''
  }) ;  
  const user_id : any = UrlParams('id') ? UrlParams('id')  : 0;
  
  useEffect( () => { 
    AuthGetData('/user', {id: user_id}).then((response) => {
      if(response.success ){ 
        if(response.data){
          setUser(response.data); 
          setValue("name",  response.data.name );
          setValue("email",  response.data.email );
          setValue("id",  response.data.id );
        }else{
          setUserNotfound(true);
          setError('User not found!');
        }
      } 
      
    })
   
  },[]);
  
  const  onSubmit = async (FormData: any) => {
    setLoading(true);
    setError('');
   
    const response = await AuthPostData('/update-user', FormData) ; 
    if(response.success ){ 
      toast.success('Updated', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    }
    else{
      setLoading(false);
      setError(response.message);
    }
     
  };
  if(userNotfound){
    return (
      <div className="pt-3"><div className='alert alert-danger' dangerouslySetInnerHTML={{__html: error}}></div></div> 
    );
  }
  return ( 
     <div>
      <ToastContainer />  
      <PageTitle title="Add User" />  
      
      <div className="add-project box">
      <h3 className="section-heading">User info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Name</label>
            <input  className="form-control"   {...register("name", { required: "Name is required" })} type="text"  />
            {errors.name && <div className='w-100'><span className="error text-red">{errors.name?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Email</label>
            <input  className="form-control"   {...register("email", { required: "email is required", pattern: { value: /\S+@\S+\.\S+/, message: "Entered value does not match email format" } })}  />
            {errors.email && <div className='w-100'><span className="error text-red">{errors.email?.message?.toString()}</span></div>} 
          </div>
           
          <div className="form-group">
            <label>Password</label>
            <input type="password" className="form-control" {...register('password', {
                required: false,
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters"
                }
              })} onChange={(e) => { setUser({...user, password: e.target.value})}}
            />
            {errors.password && <div className='w-100'><span className="error text-red">{errors.password?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input type="password" className="form-control" {...register('c_password', {
                required: false,
                validate: (val: string) => {
                  if (watch('password') != val) {
                    return "Your passwords do no match";
                  }
                },
              })}
            />
            {errors.c_password && <div className='w-100'><span className="error text-red">{errors.c_password?.message?.toString()}</span></div>} 
          </div>
            
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={loading ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Update{loading ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/users'}>Cancel</Link>
        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default EditUser;
