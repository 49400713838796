import React, { useEffect, useState } from 'react';  
import { Link } from 'react-router-dom'; 
import { AuthGetData } from '../hook';
import Loader from './Loader';
import dateFormat from "dateformat";

const  ProjectsList = () => {
  const [projects, setProjects] = useState([]) ;
  const [loading, setLoading] = useState(false) ;
  
  useEffect( () => {
    setLoading(true); 
    AuthGetData('/projects', {}).then((response) => {
      if(response.success ){ 
        setProjects(response.data);
      } 
      else{
         
      }
      setLoading(false);
    });  
     
  },[]);
  
  
 

  return ( 
    <div className="table-container">
      <table className ="table table-bordered table-hover border-0 data-tbl">
        <thead>
        <tr>
          <th>ID, Project Name, Client name</th>
          <th>Date Created</th>
          <th>Last Modified</th>
          <th>Contracts</th>
          <th>Status</th> 
          <th className="action-col"></th>
            
        </tr>
        </thead>
        <tbody>
          {loading ? <tr><td colSpan={6}><Loader /></td></tr> : ( 
          projects.map((project: any, index) => (
          <tr key={index}>
            <td>{project.project_name}</td>
            <td>{dateFormat(project.created_at, "dd.mm.yyyy - h:MM TT") }</td>
            <td>{dateFormat(project.updated_at, "dd.mm.yyyy - h:MM TT") }</td>
            <td>{project.contract_count}</td>
            <td><span className={project.status == 'Completed' ? "status bg-green" : "status bg-red"}>{project.status}</span></td> 
            <td>
              <Link to={"/project?id="+project.id} className="action-btn">Enter Project</Link> 
              <Link to={"/report?id="+project.id} className="action-btn">Analystics</Link>   
            </td>
          </tr> 
          ))
          )
          }
          
        
        </tbody>
        
      </table>
    </div>
  );
       
}
export default ProjectsList