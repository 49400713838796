import React, { useEffect, useState } from 'react'; 
import { useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthPostData, PostData } from '../hook';
const AddEditProject = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [error, setError] = useState('') ;
  const  onSubmit = async (FormData: any) => {
    setLoading(true);
    setError('');
    const response = await AuthPostData('/add-project', FormData) ; 
    if(response.success ){ 
      navigate("/"); 
    }
    else{
      setLoading(false);
      setError(response.message);
    }
     
  };
  return (  
     <div>
      <PageTitle title="Add project" />  
      
      <div className="add-project box">
      <h3 className="section-heading">Project info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label >Project No.</label>
            <input  className="form-control"   {...register("project_no", { required: "Project No. is required" })} type="text" />
            {errors.project_no && <div className='w-100'><span className="error text-red">{errors.project_no?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Project Name</label>
            <input  className="form-control"   {...register("project_name", { required: "Project Name is required" })} type="text" />
            {errors.project_name && <div className='w-100'><span className="error text-red">{errors.project_name?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Remark</label>
            <textarea  className="form-control"   {...register("remark", { required: false })}></textarea>
            {errors.remark && <div className='w-100'><span className="error text-red">{errors.remark?.message?.toString()}</span></div>} 
          </div>
          {error !== '' ? <div className="my-2 alert alert-danger">{error}</div> : ''} 
          <button type="submit" className={loading ? 'btn btn-primary  d-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Add{loading ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/'}>Cancel</Link>


        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default AddEditProject;
