import React from 'react';
type Props = {
  title: string;
};
const PageTitle = (props: Props) => {
  return (
    <div className="row mb-2 mt-4">
      <div className="col-sm-12">
      <h1 className="  page-title">{props.title}</h1>
      </div>  
    </div>  
  )
};
export default PageTitle;
 