import { atom } from 'recoil';

 
export const pageTitleState = atom({
  key: 'pageTitle',
  default: "Dashboard",
});
 
export const currentMenuState = atom({
  key: 'currentMenu',
  default: "dashboard",
});