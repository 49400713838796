import React, { useEffect, useState } from 'react'; 
import { Controller, useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthGetData, AuthPostData, PostData, UrlParams } from '../hook';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
const AddEditContract = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue , formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [error, setError] = useState('') ;
  const [projectOptions, setProjectOptions] = useState([]) ;
  const [vendorOptions, setVendorOptions] = useState([]) ;
  const [selectedProject, setSelectedProject] = useState({'value':'', 'label': ''}) ;
  const [selectedVendor, setSelectedVendor] = useState({'value':'', 'label': ''}) ;
 
  const project_id : any = UrlParams('project_id') ? UrlParams('project_id')  : 0;
  useEffect( () => { 
    
    AuthGetData('/project_options', {}).then((response) => {
      if(response.success ){ 
        setProjectOptions(response.data);
        const pr_selected = response.data.find(( el:any ) => el.value === parseInt( project_id)); 
        if( typeof pr_selected !== 'undefined'){
          setSelectedProject(pr_selected);
          setValue('project_id', project_id);
        } 
      }  
    });  
    AuthGetData('/vendor_options', {}).then((response) => {
      if(response.success ){ 
        setVendorOptions(response.data);  
      }  
    });  
     
  },[]);
  
  const  onSubmit = async (FormData: any) => {
    setLoading(true);
    setError('');
    FormData.uid =  window.sessionStorage.getItem("uid");
    const response = await AuthPostData('/add-contract', FormData) ; 
    if(response.success ){ 
      navigate('/project?id='+project_id); 
    }
    else{
      setLoading(false);
      setError(response.message);
    }
     
  };
  return ( 
     <div>
      <PageTitle title="Add Contract" />  
      
      <div className="add-project box">
      <h3 className="section-heading">Contract info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label >Project</label> 
            <Controller
              name="project_id"
              control={control}
              rules={{ required: 'Please select a project' }}
               
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select options={projectOptions} onChange={ (e:any) => {setValue('project_id', e?.value); setSelectedProject(e)  } }  onBlur={onBlur} value={selectedProject}  defaultValue={selectedProject} ></Select> 
              )}
            />  
             {errors.project_id && <div className='w-100'><span className="error text-red">{errors.project_id?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label >Contract No.</label>
            <input  className="form-control"   {...register("contract_no", { required: "Contract No. is required" })} type="text" />
            {errors.contract_no && <div className='w-100'><span className="error text-red">{errors.contract_no?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Amount</label>
            <Controller
              name="amount"
              rules={{ required: 'Amount is required' }}
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <NumericFormat 
                  className="form-control"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="HK$"
                  decimalScale={2}
                  getInputRef={ref}
                  {...rest}
                />
              )}
            /> 
            {errors.amount && <div className='w-100'><span className="error text-red">{errors.amount?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label >Vendor</label> 
            <Controller
              name="vendor_id"
              control={control}
              rules={{ required: 'Please select a vendor' }}
               
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select options={vendorOptions} onChange={ (e:any) => {setValue('vendor_id', e?.value); setSelectedVendor(e)  } }  onBlur={onBlur} value={selectedVendor}  defaultValue={selectedVendor} ></Select> 
              )}
            />  
             {errors.vendor_id && <div className='w-100'><span className="error text-red">{errors.vendor_id?.message?.toString()}</span></div>} 
          </div>
         
          
            
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={loading ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Add{loading ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/project?id='+project_id}>Cancel</Link>
        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default AddEditContract;
