import PageTitle from '../components/PageTitle';

const Page404 = () => {
  
  return (
    <>
    <PageTitle title="404 Error!" />    
    <div>Page Not Found</div>
    </>
 
  );
}

export default Page404;
