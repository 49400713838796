import React, { useEffect, useState } from 'react'; 
import $ from 'jquery';
import {AuthGetData, UpdatePageTitle, UrlParams} from '../hook';
import { Link, useLocation } from 'react-router-dom';
import PageTitle from '../components/PageTitle'; 
import Loader from '../components/Loader';
import dateFormat from 'dateformat';
import { NumericFormat} from 'react-number-format';
const Users = () => {
  const [users, setUsers] = useState([]) ;
  const [loading, setLoading] = useState(false) ; 
  useEffect( () => {
    setLoading(true); 
    AuthGetData('/users', {}).then((response) => {
      if(response.success ){ 
        setUsers(response.data);
      } 
      else{
         
      }
      setLoading(false);
    });  
     
  },[]);
  
  return ( 
     <div>
      <PageTitle title="Users" />  
      
      <div className="py-2 mb-3"><Link className="btn btn-primary" to={'/add-user'}>Add User</Link></div>
      <div className="payments-list list-data"> 
      <div className="table-container">
        <table className ="table table-bordered table-hover border-0 data-tbl">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th> 
                <th>Action</th>
              </tr>
              </thead>
            <tbody>
              { (users.length < 1 && !loading ) ? <tr><td colSpan={10}>No User!</td></tr> : ( 
                loading ? <tr><td colSpan={10}><Loader /></td></tr> : ( 
                  users.map((user: any, index) => (
                <tr key={index}>
                
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
               
                  
                  <td><Link to={"/edit-user?id=" + user.id} className="action-btn">Edit</Link></td>
                </tr>
                )))
              )
            }
            </tbody>
            
          </table>
        </div>       
      </div>
       
        
    </div>

 
  );
}

export default Users;
