import React from 'react'; 
import { Link, useLocation } from 'react-router-dom'; 
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentMenuState } from '../state';
function Sidebar() {
  const currentMenu = useRecoilValue(currentMenuState);
  const location = useLocation();
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4">
      {/* Brand Logo */}
       
      <Link to="/" className='brand-link'><img src={process.env.PUBLIC_URL + 'dist/img/Side-Logo.svg'} /></Link>   
        
    
      {/* Sidebar */}
      <div className="sidebar">
         
         
        
        {/* Sidebar Menu */}
        <nav className="mt-2 mb-3">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {/* Add icons to the links using the .nav-icon class
            with font-awesome or any other icon font library */}
            <li className="nav-item "> 
              <Link to="/" className="nav-link active">
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>
                  Dashboard 
                </p>
              </Link> 
            </li>
            <li className={"nav-item menu-is-opening menu-open"}>
              <a href="#" className="nav-link">
                <i className="nav-icon toggle-icon fas fa-angle-right" />
                <p>
                  Projects
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a  className={"nav-link " + (location.pathname == '/project' ? "active" : "")}>
                   
                    <i className="nav-icon fas fa-file-signature  fa-fw"></i>
                    <p>Contracts</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a  className={"nav-link " + (location.pathname == '/contract' ? "active" : "")}>
                    <i className="nav-icon fas fa-info-circle fa-fw" /> 
                    <p>Contract Details</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a  className={"nav-link " + (location.pathname == '/report' ? "active" : "")}>
                  <i className="nav-icon far fa-chart-bar fa-fw" />  
                  <p>Report / Analytics</p>
                  </a> 
                </li> 
                 
              </ul>
            </li>
            <li className="nav-line"></li>
            <li className="nav-header">Administration</li>
            <li className={"nav-item " + ( ["/users", "/add-user"].includes(location.pathname )  ? "menu-is-opening menu-open" : "")}>
              <a href="#" className="nav-link">
                <i className="nav-icon toggle-icon fas fa-angle-right" />
                <p>Users</p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/users" className="nav-link"> 
                    <i className="nav-icon fas fa-users"></i>
                    <p>List Users</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/add-user" className="nav-link"> 
                    <i className="nav-icon fas fa-user-plus"></i>
                    <p>Add User</p>
                  </Link>
                </li>
                 
              </ul>
            </li>

            <li className={"nav-item " + ( ["/accounts", "/add-account"].includes(location.pathname )  ? "menu-is-opening menu-open" : "")}>
              <a href="#" className="nav-link">
                <i className="nav-icon toggle-icon fas fa-angle-right" />
                <p>Acounts</p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/accounts" className={"nav-link " + (location.pathname == '/accounts' ? "active" : "")}> 
                    <i className="nav-icon fas fa-money-check-alt fa-fw"></i>
                    <p>List Accounts</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/add-account" className={"nav-link " + (location.pathname == '/add-account' ? "active" : "")}> 
                    <i className="nav-icon fas fa-money-check fa-fw"></i>
                    <p>Add Account</p>
                  </Link>
                </li>
                 
              </ul>
            </li>
            <li className={"nav-item "  + ( ["/vendors", "/add-vendor"].includes(location.pathname )  ? "menu-is-opening menu-open" : "")}>
              <a href="#" className="nav-link">
                <i className="nav-icon toggle-icon fas fa-angle-right" />
                <p>Vendors</p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/vendors" className={"nav-link " + (location.pathname == '/vendors' ? "active" : "")}> 
                    <i className="nav-icon fas fa-address-book fa-fw"></i>
                    <p>List Vendors</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/add-vendor" className={"nav-link " + (location.pathname == '/add-vendor' ? "active" : "")}> 
                    <i className="nav-icon far fa-address-book fa-fw"></i>
                    <p>Add Vendor</p>
                  </Link>
                </li>
                 
              </ul>
            </li>

                
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>

 
  );
}

export default Sidebar;
